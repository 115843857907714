<template>
    <div v-if="device">
        <div class="container repair-container">

            <!-- Filter -->
            <div class="row">
                <div class="col-md-12">
                    <label class="mt-2">Enter the details the customer has given you about this fault</label>
                </div>
            </div>

            <!-- Data entry -->
            <div class="row mb-4">
                <div class="col-md-12">
                    <label>Fault description</label>
                    <textarea v-model="description" class="form-control" rows="3" maxlength="300" placeholder="Enter a description"></textarea>
                    <p class="text-left text-muted text-xs pl-1 mb-0">Enter a minimum of 20 characters</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label>Fault occurrence frequency</label>
                    <select v-model="frequency" class="custom-select">
                        <option :value="null">Select frequency of occurrence</option>
                        <option value="Always">Always</option>
                        <option value="More than once a day">More than once a day</option>
                        <option value="Once a day">Once a day</option>
                        <option value="Intermittent">Intermittent</option>
                    </select>
                </div>
            </div>

            <!-- Footer -->
            <fixed-footer
                    @previousButtonClicked="saveDetails()"
                    @nextButtonClicked="saveDetails()"
                    :class="{'active': footerActive}"
                    :nextLabel="this.nextRepairStep($route.name).title"
                    :nextRoute="this.nextRepairStep($route.name).routeName"
                    :prevRoute="this.prevRepairStep($route.name).routeName">
            </fixed-footer>

        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {
        props:["jobId", "deviceId"],

        data() {
            return {
                description: null,
                frequency: null
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                });
                this.description = this.device.customer_fault_description;
                this.frequency = this.device.fault_occurrence_frequency;
            });
        },

        computed: {
            ...mapGetters([
                "device",
                "isJobManager",
                "nextRepairStep",
                "prevRepairStep",
            ]),

            /** Check if footer should be active */
            footerActive() {
                if (!this.description || this.description.length < 20 || !this.frequency) {
                    return false;
                }

                return true;
            }
        },

        watch: {
            // description(desc) {
            //     this.description = desc.replace("  ", " ");
            // }
        },

        methods: {
            ...mapActions([
                "loadDeviceForJob",
                "setCurrentStep",
                "setDefectDescriptions"
            ]),

            saveDetails() {
                this.setDefectDescriptions({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    description: this.description,
                    frequency: this.frequency
                });
            }
        }
    }
</script>

<style scoped>

</style>